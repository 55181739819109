
  var st=window.jsSettings||{};
  switch(st.page||"") {
    case "app-home":
        import( /* webpackChunkName: 'app-home' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/app-home.js')
     break;
    case "app-link":
        import( /* webpackChunkName: 'app-link' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/app-link.js')
     break;
    case "app":
        import( /* webpackChunkName: 'app' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/app.js')
     break;
    case "contact":
        import( /* webpackChunkName: 'contact' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/contact.js')
     break;
    case "content":
        import( /* webpackChunkName: 'content' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/content.js')
     break;
    case "print":
        import( /* webpackChunkName: 'print' */ 'E:/workspaces/huytran/HungVuongTech/schoolnet/khohoclieu/SchoolNet.Application.Website/wwwroot/js/pages/print.js')
     break;
    
  }
  module.exports={};
